import Broadcast from '../../../../api/resquests/chat/broadcast'

const broadcast = new Broadcast();
const broadcastModule = {
    namespaced: true,
    state: {
        items: [],
        lookups: [],
        load: false,
        total: 0,
        item: {},
        actions: null,
        logLoad: false
    },
    getters: {
        items(state) {
            return state.items
        },
        item(state) {
            return state.item
        },

        load(state) {
            return state.load;
        },
        lookups(state) {
            return state.lookups
        },
        
        total(state) {
            return state.total;
        }

    },
    mutations: {

        SET_TOTAL(state, total) {
            state.total = total;
        },
        SET_ITEMS(state, items) {
            state.items = items;
        },
        SET_LOAD(state, load) {
            state.load = load;
        },
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups;
          },
        SET_ITEM(state, item) {
            state.item = item;
        }
    },
    actions: {
        items({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD', true);
                broadcast.list(payload.query)
                    .then(response => {
                        commit('SET_ITEMS', response.data);
                        commit('SET_TOTAL', response.meta.count);
                        commit('SET_LOAD', false);
                        commit('SET_LOOKUPS', response.meta.lookup);
                        resolve(response);
                    }).catch(error => reject(error));
            });
        },

        broadCast({commit}, payload) {
            commit('app/UPDATE_LOAD', true, {root: true});
            commit('SET_ITEMS_LOAD', true);
            return new Promise((resolve, reject) => {
                broadcast.broadCast(payload).then(response => {
                    commit('app/UPDATE_LOAD', false, {root: true});
                    commit('SET_ITEMS_LOAD', false);
                    resolve(response);
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, {root: true});
                    commit('SET_ITEMS_LOAD', false);
                    reject(error);
                });
            });
        },

        messages({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD', true);
                broadcast.messages(payload).then(response => {
                    commit('SET_ITEMS', response.data);
                    commit('SET_TOTAL', response.meta.count);
                    commit('SET_LOAD', false);
                    resolve();
                }).catch(error => {
                    reject(error);
                })
            })
        },

        message({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD', true);
                broadcast.message(payload.id, payload.resource).then(response => {
                    commit('SET_LOAD', false);
                    resolve();
                }).catch(error => {
                    reject(error);
                })
            })
        }
    }
}

export default broadcastModule;
