import Resource from '../../resource'
import request from '../../../utils/request'

class Slot extends Resource {
  constructor() {
    super('slots')
  }
//edit slot
  put(id, query) {
    return request({
        url: `/${this.uri}${id ? `/${id}` : ''}`,
        method: 'put',
        data: query,
    })
}
  add(query) {
    return request({
        url: `/${this.uri}`,
        method: 'put',
        data: query,
    })
}
//Add Multi or single slot
  addSlot(query) {
    return request({
        url: '/slots/add',
        method: 'post',
        data: query,
    })
}

}

export { Slot as default }
