import Resource from '../../resource';
import request from '../../../utils/request';
import store from '@/store/index'

class Payment extends Resource {
    constructor() {
        super('payments/payment-slip');
    }
    dependentLookups(query, id) {
        return request({
            url: `payments/payment-slip`,
            method: 'post',
            data: query,
        })
    }
    printPaymentSlip(id){
        return request({
            url: `payments/export-payment-slip/${id}`,
            method: 'post',
        })
    }
}

export {Payment as default};