import Resource from '../../../api/resource';
import request from '../../../utils/request';

class Broadcast extends Resource {

    constructor() {
        super('broadcasts');
    }

    messages(payload) {
        return request({
            url: `/broadcasts/messages/${payload.id}`,
            method: 'post',
            data: payload.query,
        });
    }

    message(id, resource) {
        return request({
            url: `/broadcasts/message/${id}`,
            method: 'post',
            data: resource,
        });
    }

    broadCast(query) {
        return request({
          url: `/my-chats/broadcast`,
          method: 'post',
          data: query,
        })
    }
}

export {Broadcast as default};
