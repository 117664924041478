import Resource from "../resource";
import request from "../../utils/request";

class Admission extends Resource {
  constructor() {
    super("admission");
  }

  verifiedEmail(data) {
    return request({
      url: `/admission/apply`,
      method: "put",
      data:data.query,
      headers: { 'Authorization': 'hx1v3BKihvERof3eD1Wi7iynAUO5Z6vzVQIo3GNCMQ0-' },
      admission:true
    });
  }
  lookups(id) {
    return request({
      url: `/admission/website/applicant/lookups${id ? '/' + id : ''}`,
      method: "get",
      headers: {
        'Authorization': "hx1v3BKihvERof3eD1Wi7iynAUO5Z6vzVQIo3GNCMQ0-"
      },
      admission:true,
    });
  }

  admissionData(data) {
    return request({
      url: `/website/admissionData`,
      method: "get",
      params: {
        language: data.lang,
      },
      headers: { 'Authorization': 'hx1v3BKihvERof3eD1Wi7iynAUO5Z6vzVQIo3GNCMQ0-' },
      admission:true,
    });
  }

  print(payload) {
    return request({
      url: `/admission/website/print-pdf/${payload.id}${payload.installment_id ? '/'+payload.installment_id : ''}`,
      method: "get",
      responseType: 'blob',
      headers: { 'Authorization': 'hx1v3BKihvERof3eD1Wi7iynAUO5Z6vzVQIo3GNCMQ0-' },
      admission:true

    });
  }

  needTraning(data) {
    return request({
      url: `/admission/faculty/needTraining/${data.type}/${data.id}`,
      method: "get",
      headers: { 'Authorization': 'hx1v3BKihvERof3eD1Wi7iynAUO5Z6vzVQIo3GNCMQ0-' },
      admission:true
    });
  }

  getApplicant(data) {
    return request({
      url: `/admission/${data.id}/${data.key}`,
      method: "get",
      headers: { 'Authorization': 'hx1v3BKihvERof3eD1Wi7iynAUO5Z6vzVQIo3GNCMQ0-' },
      admission:true,
    });
  }

  createTransaction(payload) {
    return request({
      url: `/admission/website/transaction/create${payload.id?'/'+payload.id:''}`,
      method: "post",
      data:payload.query,
      headers: { 'Authorization': 'hx1v3BKihvERof3eD1Wi7iynAUO5Z6vzVQIo3GNCMQ0-' },
      admission:true
    });
  }

  checkBankMisrPaymentStatus(data) {
    return request({
      url: `/admission/website/transaction/check/${data.transaction}${data.id?'/'+data.id:''}`,
      method: "post",
      headers: { 'Authorization': 'hx1v3BKihvERof3eD1Wi7iynAUO5Z6vzVQIo3GNCMQ0-' },
      admission:true
    });
  }
  save(data) {
    return request({
      url: `/admission/${data.id || 2}/${data.key}`,
      method: "post",
      data:data.query,
      headers: { 'Authorization': 'hx1v3BKihvERof3eD1Wi7iynAUO5Z6vzVQIo3GNCMQ0-' },
      admission:true
    });
  }


  createDirectories(payload) {
    return request({
      url: `/archive`,
      method: "put",
      data: payload,
      headers: { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzY5YzAxOGNmOWJkMmY1NTJhZTVkNTgxZDYwZjg0Y2I2NDcyNjAzZmY4NjU4ZmM0MTI3MmFmMmE0ZWI3Y2JlOTFiMDU5Zjg2Zjg4MDdjMWUiLCJpYXQiOjE2NTA4MTUzMzUsIm5iZiI6MTY1MDgxNTMzNSwiZXhwIjoxNjgyMzUxMzM1LCJzdWIiOiIxNTk5MDQiLCJzY29wZXMiOltdfQ.RRzv5BY4WpmR5TI67hNr4512Ii26YmSIPG7zok4SF_AqXnGo9keD5U2gUnTsSPqsMApPrKsHRKymXzsTDS-SaD-_fvM5o_eBg-lordjKz24cgrT_h7kzx16NFIjZEIfkA38stLBF3luiNaexzMpUcYhFbjU_k5sSwK6bLKqmyz221UJguD6A08vy6AFLpsj-JNUJ4OpZb8XmYAPCsL3IdnewjErqLi3XfRYU8bR4qM-KiqyzJnVaj09n4by2XDVoCd1GkOZ_IeO2JLdZCVX5wwPv-CmHskRRLN-kLAAER-JpwHzD_B_P_2jXapfzIrccYgloECRxuDpy9ifjuau4JmzPhmqxE8tU71RqnMLwhSspFLWOtk269ONgMsVvlNJuksD6bZLwBpFsMvge81yk9pJBCTlgnmO9CyD5BfFFTX32XSxDDXGHaOjWP_FWewa3M608EbvLlW6KI4chfq8wYK8q_5J0N1PFhI8Cxem5wsQ5-KIn-Uz4u2eoYvDrJcbiQ6YTIJb5sNMy1HXuH6nGAiaCN0zhp7KKVDbaheIb0FoKLTyhv_p7lQTsFepKE6UsrJVDHYBzbEvkNmOXdBDxdf83EaWD9nKnv_0LWCWiIgQdNDhFyXtEOlheo0T1m00E1CsZp6IJRunU6nN-TThAeFU8FTrdYSKdmANS1Q-uZXg' },
      admission:true
    });
  }
  destroy(id) {
    return request({
      url: `/archive/${id}`,
      method: 'delete',
      admission:true
    })
  }
  pay(id, query) {
    let url = `/payments/pay/${id}`;
    return request({
        url: url,
        method: 'post',
        data: query,
        admission:true
    })
}
  upload(resource, id) {
    return request({
      url: `/archive/upload${id}`,
      method: 'post',
      data: resource,
      admission:true
    })
  }
  setContent(payload) {
    return request({
      url: `/archive/update_page/${payload.id}`,
      method: 'post',
      data: {
        body: payload.body,
        language: payload.language
      },
      headers: { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzY5YzAxOGNmOWJkMmY1NTJhZTVkNTgxZDYwZjg0Y2I2NDcyNjAzZmY4NjU4ZmM0MTI3MmFmMmE0ZWI3Y2JlOTFiMDU5Zjg2Zjg4MDdjMWUiLCJpYXQiOjE2NTA4MTUzMzUsIm5iZiI6MTY1MDgxNTMzNSwiZXhwIjoxNjgyMzUxMzM1LCJzdWIiOiIxNTk5MDQiLCJzY29wZXMiOltdfQ.RRzv5BY4WpmR5TI67hNr4512Ii26YmSIPG7zok4SF_AqXnGo9keD5U2gUnTsSPqsMApPrKsHRKymXzsTDS-SaD-_fvM5o_eBg-lordjKz24cgrT_h7kzx16NFIjZEIfkA38stLBF3luiNaexzMpUcYhFbjU_k5sSwK6bLKqmyz221UJguD6A08vy6AFLpsj-JNUJ4OpZb8XmYAPCsL3IdnewjErqLi3XfRYU8bR4qM-KiqyzJnVaj09n4by2XDVoCd1GkOZ_IeO2JLdZCVX5wwPv-CmHskRRLN-kLAAER-JpwHzD_B_P_2jXapfzIrccYgloECRxuDpy9ifjuau4JmzPhmqxE8tU71RqnMLwhSspFLWOtk269ONgMsVvlNJuksD6bZLwBpFsMvge81yk9pJBCTlgnmO9CyD5BfFFTX32XSxDDXGHaOjWP_FWewa3M608EbvLlW6KI4chfq8wYK8q_5J0N1PFhI8Cxem5wsQ5-KIn-Uz4u2eoYvDrJcbiQ6YTIJb5sNMy1HXuH6nGAiaCN0zhp7KKVDbaheIb0FoKLTyhv_p7lQTsFepKE6UsrJVDHYBzbEvkNmOXdBDxdf83EaWD9nKnv_0LWCWiIgQdNDhFyXtEOlheo0T1m00E1CsZp6IJRunU6nN-TThAeFU8FTrdYSKdmANS1Q-uZXg' },
      admission:true
    })
  }
  rename(resource) {
    return request({
      url: `/archive${resource.id ? '/' +resource.id : ''}`,
      method: 'put',
      data: resource.resource,
      headers: { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzY5YzAxOGNmOWJkMmY1NTJhZTVkNTgxZDYwZjg0Y2I2NDcyNjAzZmY4NjU4ZmM0MTI3MmFmMmE0ZWI3Y2JlOTFiMDU5Zjg2Zjg4MDdjMWUiLCJpYXQiOjE2NTA4MTUzMzUsIm5iZiI6MTY1MDgxNTMzNSwiZXhwIjoxNjgyMzUxMzM1LCJzdWIiOiIxNTk5MDQiLCJzY29wZXMiOltdfQ.RRzv5BY4WpmR5TI67hNr4512Ii26YmSIPG7zok4SF_AqXnGo9keD5U2gUnTsSPqsMApPrKsHRKymXzsTDS-SaD-_fvM5o_eBg-lordjKz24cgrT_h7kzx16NFIjZEIfkA38stLBF3luiNaexzMpUcYhFbjU_k5sSwK6bLKqmyz221UJguD6A08vy6AFLpsj-JNUJ4OpZb8XmYAPCsL3IdnewjErqLi3XfRYU8bR4qM-KiqyzJnVaj09n4by2XDVoCd1GkOZ_IeO2JLdZCVX5wwPv-CmHskRRLN-kLAAER-JpwHzD_B_P_2jXapfzIrccYgloECRxuDpy9ifjuau4JmzPhmqxE8tU71RqnMLwhSspFLWOtk269ONgMsVvlNJuksD6bZLwBpFsMvge81yk9pJBCTlgnmO9CyD5BfFFTX32XSxDDXGHaOjWP_FWewa3M608EbvLlW6KI4chfq8wYK8q_5J0N1PFhI8Cxem5wsQ5-KIn-Uz4u2eoYvDrJcbiQ6YTIJb5sNMy1HXuH6nGAiaCN0zhp7KKVDbaheIb0FoKLTyhv_p7lQTsFepKE6UsrJVDHYBzbEvkNmOXdBDxdf83EaWD9nKnv_0LWCWiIgQdNDhFyXtEOlheo0T1m00E1CsZp6IJRunU6nN-TThAeFU8FTrdYSKdmANS1Q-uZXg' },
      admission:true
    })
  }


}

export { Admission as default };
