import Resource from '../../resource'
import request from '../../../utils/request'

class Marks extends Resource {
    constructor() {
        super('marks')
    }



}

export { Marks as default }