import Chat from '../../../../api/resquests/chat/chat'

const chat = new Chat();
const chatModule = {
    namespaced: true,
    state: {
        items: [],
        load: false,
        total: 0,
        item: {},
        actions: null,
        lookups: null,
        logLoad: false
    },
    getters: {
        items(state) {
            return state.items
        },
        item(state) {
            return state.item
        },

        load(state) {
            return state.load;
        },
        total(state) {
            return state.total;
        },
        lookups(state) {
            return state.lookups
          },
    },
    mutations: {

        SET_TOTAL(state, total) {
            state.total = total;
        },
        SET_ITEMS(state, items) {
            state.items = items;
        },
        SET_LOAD(state, load) {
            state.load = load;
        },
        SET_ITEM(state, item) {
            state.item = item;
        },
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups;
          },
    },
    actions: {
        items({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD', true);
                chat.list(payload.query)
                    .then(response => {
                        commit('SET_ITEMS', response.data);
                        commit('SET_TOTAL', response.meta.count);
                        commit('SET_LOAD', false);
                        resolve(response);
                    }).catch(error => reject(error));
            });
        },

        broadCast({commit}, payload) {
            commit('app/UPDATE_LOAD', true, {root: true});
            commit('SET_ITEMS_LOAD', true);
            return new Promise((resolve, reject) => {
                chat.broadCast(payload).then(response => {
                    commit('app/UPDATE_LOAD', false, {root: true});
                    commit('SET_ITEMS_LOAD', false);
                    resolve(response);
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, {root: true});
                    commit('SET_ITEMS_LOAD', false);
                    reject(error);
                });
            });
        },

        messages({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD', true);
                chat.messages(payload).then(response => {
                    commit('SET_ITEMS', response.data);
                    commit('SET_TOTAL', response.meta.count);
                    commit('SET_LOAD', false);
                    resolve();
                }).catch(error => {
                    reject(error);
                })
            })
        },

        message({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOAD', true);
                chat.message(payload.id, payload.resource).then(response => {
                    commit('SET_LOAD', false);
                    resolve();
                }).catch(error => {
                    reject(error);
                })
            })
        },
        GET_LOOKUPS({ commit }, lookupModules) {
            commit('app/UPDATE_LOAD', true, { root: true });
            return new Promise((resolve, reject) => {
              chat.getLookups(lookupModules).then(response => {
                if(response.success){
                  commit('SET_LOOKUPS', response.success)
                }
                commit('app/UPDATE_LOAD', false, { root: true })
                resolve(response)
              })
            })
          },
    }
}

export default chatModule;
